<template>
  <button
    type="button"
    class="btn"
    :class="[
      color,
      size,
      {
        'btn-fill': type === 'fill',
        'btn-solid': type === 'solid',
        'btn-text': type === 'text',
        'btn-tag': type === 'tag',
        'icon-left': iconClass && iconType == 'left',
        'icon-right': iconClass && iconType == 'right',
        'btn-withoutbg': withoutBg,
        disabled: disabled,
        loading: loading,
        shadow: shadow,
      },
    ]"
    :disabled="disabled"
    :style="`${minWidth ? `min-width: ${minWidth};` : ''}${
      width ? `width: ${width};` : ''
    }`"
    @click="clickBtn()">
    <template v-if="!loading">
      <svg-icon
        v-if="iconClass"
        :iconClass="iconClass"
        class="icon"
        :style="iconColor ? `color: ${iconColor}` : 'unset'" />
      <slot>{{ $t('Button') }}</slot>
    </template>
    <template v-if="loading">
      <div class="loader"></div>
    </template>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {},
  props: {
    color: { type: String, default: 'blue' }, // 'blue', 'yellow', 'white'
    size: { type: String, default: 'xl' }, // 'xl', 'l', 'm'
    type: { type: String, default: 'fill' }, // 'fill', 'solid', 'text'
    iconType: { type: String, default: 'left' }, // 'left', 'right'
    iconClass: { type: String, default: '' }, // iconClass的名稱
    iconColor: { type: String, default: '' }, // icon的色碼
    loading: { type: Boolean, default: false },
    withoutBg: { type: Boolean, default: false }, // 透明背景
    disabled: { type: Boolean, default: false },
    shadow: { type: Boolean, default: false }, // 是否顯示陰影
    minWidth: { type: String, default: '' }, // 按鈕最小寬度(px)
    width: { type: String, default: '' }, // 按鈕寬度(px)
  },
  data() {
    return {}
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    clickBtn() {
      this.$emit('clickFunction', this)
    },
  },
})
</script>
<style lang="scss" scoped>
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: $blue;
  padding: 11px 20px;
  border-radius: 24px;
  min-width: 120px;
  box-sizing: border-box;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  height: 48px;
  @include lg-breakpoint {
    &:hover {
      background-color: $blueDark;
    }
  }
  // &:visited,
  // &:focus,
  // &:active {
  //   background-color: $blueDeepDark;
  // }
  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.4;
  }

  // Size: XL
  &.xl {
    padding: 11px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 48px;
  }
  // Size: L
  &.l {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 40px;
  }
  // Size: M
  &.m {
    padding: 4px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    height: 32px;
    .loader {
      width: 20px;
      height: 20px;
    }
  }

  // icon: left
  &.icon-left {
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  // icon: right
  &.icon-right {
    .icon {
      width: 24px;
      height: 24px;
      margin-left: 4px;
      order: 1;
    }
  }

  // Type: Fill
  &.btn-fill {
    .loader {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 4px solid $white40;
      border-top: 4px solid $white;
      animation: rotating-spinner 0.8s linear infinite;
    }

    //color: Blue
    &.blue {
      background-color: $blue;
      @include lg-breakpoint {
        &:hover {
          background-color: $blueDark;
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   background-color: $blueDeepDark;
      // }
    }
    &.light-blue {
      background-color: rgba(54, 154, 217, 0.1);
      color: #369ad9;
      @include lg-breakpoint {
        &:hover {
          background-color: rgba(70, 172, 218, 0.4);
          color: #369ad9;
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   background-color: $blueDeepDark;
      // }
    }
    //color: Yellow
    &.yellow {
      background-color: $yellow;
      @include lg-breakpoint {
        &:hover {
          background-color: $yellowDark;
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   background-color: $yellowDeepDark;
      // }
    }

    //color: White
    &.white {
      background-color: $white;
      color: $black;
      @include lg-breakpoint {
        &:hover {
          background-color: $grey;
          color: $black;
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   background-color: $greyDark;
      // }
      .loader {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 4px solid #ebeef4;
        border-top: 4px solid $blue;
        animation: rotating-spinner 1s linear infinite;
      }
    }
    &.grey {
      background-color: $grey;
      color: $black;
      @include lg-breakpoint {
        &:hover {
          background-color: $blue10;
          color: $blue;
        }
      }
      &:visited,
      &:focus,
      &:active,
      &.active {
        background-color: $blue10;
        color: $blue;
      }
    }
  }

  // type: solid
  &.btn-solid {
    // height: 46px;
    background-color: $white;
    border: 1px solid $black10;
    color: $black80;
    @include lg-breakpoint {
      &:hover {
        border: 1px solid $blue;
        color: $blue;
        background-color: rgba(54, 154, 217, 0.1);
      }
    }
    .loader {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 4px solid #ebeef4;
      border-top: 4px solid $blue;
      animation: rotating-spinner 1s linear infinite;
    }
    //color: Blue
    &.blue {
      border: 1px solid $blue;
      color: $blue;
      @include lg-breakpoint {
        &:hover {
          border: 1px solid $blue;
          color: $blue;
          background-color: rgba(54, 154, 217, 0.1);
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   border: 1px solid $blueDeepDark;
      //   color: $blueDeepDark;
      //   background-color: rgba(54, 154, 217, 0.2);
      // }
      &:disabled,
      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
    //color: Grey
    &.grey {
      border: 1px solid $black10;
      color: $black80;
      @include lg-breakpoint {
        &:hover {
          border: 1px solid $blue;
          color: $blue;
          background-color: rgba(54, 154, 217, 0.1);
        }
      }
      // &:visited,
      // &:focus,
      // &:active {
      //   border: 1px solid $blueDeepDark;
      //   color: $blueDeepDark;
      //   background-color: rgba(54, 154, 217, 0.2);
      // }
      &:disabled,
      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }

  &.btn-withoutbg {
    background: none;
  }

  // type: text
  &.btn-text {
    background-color: unset;
    color: $blue;
    text-decoration-line: underline;
    padding: 8px 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    min-width: auto;
    @include lg-breakpoint {
      &:hover {
        color: $blueDark;
        background-color: unset;
      }
    }
    &.xl {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
    }
    &.l {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
    &.m {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    &.icon-left,
    &.icon-right {
      text-decoration: none;
    }
  }

  // type: Tag
  &.btn-tag {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 4px 12px;
    border-radius: 16px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    .svg-icon {
      width: 20px;
      height: 20px;
    }
    &.grey {
      background-color: $grey;
      color: $black;
      &:hover {
        background-color: $greyDark;
      }
      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &.blue {
      background-color: $blue10;
      color: $blue;
      &:hover {
      }
      &.disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
      }
    }
    &.icon-left {
      padding: 4px 12px 4px 8px;
    }
    &.icon-right {
      padding: 4px 8px 4px 12px;
    }
  }

  // Loading
  &.loading {
    pointer-events: none;
    cursor: default;
  }

  &.shadow {
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.24);
  }
}

@keyframes rotating-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

import { createApp, h, render, VNode } from 'vue'
import MessageBox from './MessageBox.vue'

interface Props {
  text: string
  title: string
  cancelBtnText: string
  confirmBtnText: string
  countdownSeconds: number
  type: string
  showCancelBtn: boolean
}

export const MsgBox = {
  show({
    text,
    title,
    cancelBtnText,
    confirmBtnText,
    countdownSeconds,
    type,
    showCancelBtn,
  }: Props) {
    const div = document.createElement('div')
    document.body.appendChild(div)

    return new Promise<void>((resolve, reject) => {
      const app = createApp({
        render: () =>
          h(MessageBox, {
            text,
            title,
            cancelBtnText,
            confirmBtnText,
            type,
            countdownSeconds,
            showCancelBtn,
            resolve,
            reject,
          }),
      })

      app.mount(div)

      app.config.globalProperties.$msgBox = {
        confirm() {
          // resolve()
        },
      }
    })
  },
}

import App from '@pages/home/home.vue'
import { createApp } from 'vue'

import { registerPlugins } from '@plugins/mapRouter'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import homeRoute from '@pages/home/homeRoute'

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
// import 'dayjs/locale/zh-tw'
//import zhTw from 'element-plus/lib/locale/lang/zh-tw' //中文

import 'virtual:svg-icons-register'
import initSvgIcon from '@/assets/icons/index'
import '@/assets/scss/app.scss'

import MasonryWall from '@yeger/vue-masonry-wall'
// import vue3GoogleLogin from 'vue3-google-login'
import { MsgBox } from '@/components/component/MessageBox'
import { useAppStore } from '@/pages/home/homeStore'
import VueCryptojs from 'vue-cryptojs'
import VueDOMPurifyHTML from 'vue-dompurify-html'

const app = createApp(App)
const router = homeRoute
const pinia = createPinia()

// 判斷網址進行轉跳
router.beforeEach((to, from, next) => {
  const query = to.query
  // 會員主頁轉跳
  if (query.action === 'profile' && query.memberId && to.path !== '/member') {
    next({ path: '/member', query: query })
  } else {
    next()
  }
})

pinia.use(createPersistedState())
app.use(VueDOMPurifyHTML, {
  namedConfigurations: {
    svg: {
      USE_PROFILES: { svg: true },
    },
    mathml: {
      USE_PROFILES: { mathMl: true },
    },
  },
  hooks: {
    afterSanitizeAttributes: currentNode => {
      if ('target' in currentNode) {
        currentNode.setAttribute('target', '_blank')
        currentNode.setAttribute('rel', 'noopener')
      }
      // set non-HTML/MathML links to xlink:show=new
      if (!('target' in currentNode) && 'href' in currentNode) {
        currentNode.setAttribute('target', '_blank')
        currentNode.setAttribute('rel', 'noopener')
      }
    },
  },
})
const appStore = useAppStore(pinia)

registerPlugins(app, router, pinia)

// app.component(
//   'VDataTableServer', VDataTableServer,
// )

app.component('VueDatePicker', VueDatePicker)
// app.use(ElementPlus, { locale: zhTw })
app.use(ElementPlus)
app.use(initSvgIcon)
app.use(MasonryWall)
app.use(VueCryptojs)
// app.use(vue3GoogleLogin, {
//   clientId: process.env.VITE_GOOGLE_LOGIN_ID,
// })

app.config.globalProperties.$msgBox = MsgBox
app.config.globalProperties.$loading = {
  show: appStore.showLoading,
  hide: appStore.hideLoading,
}
app.config.warnHandler = () => null // 關閉vue warn
app.mount('#app')

// Composables

import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/home.vue'
      ),
  },
  {
    path: '/index',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/index.vue'
      ),
  },
  {
    path: '/lineCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/lineCallback.vue'
      ),
  },
  {
    path: '/appleCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/appleCallback.vue'
      ),
  },
  {
    path: '/signupCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/registCallback.vue'
      ),
  },
  {
    path: '/hotaiCallback',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/hotaiCallback.vue'
      ),
  },
  {
    path: '/about',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/about.vue'
      ),
  },
  {
    path: '/maptest',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/maptest.vue'
      ),
  },
  {
    path: '/component',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/component.vue'
      ),
  },
  {
    path: '/member',
    component: () =>
      import(
        /* webpackChunkName: "apple" */ '@components/home/contents/member.vue'
      ),
  },
]

// const router = createRouter({
//  history: createWebHistory(process.env.BASE_URL),
//  routes,
// })

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
